@use 'sass:math';

$font_size_base: 1.6rem;

$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $white;

@mixin bp($width, $value: max) {
  @if $value == min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else if $value == max {
    @media screen and (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: $width) and (min-width: $value) {
      @content;
    }
  }
}

@function math($val_1, $val_2) {
  @return math.div($val_1, $val_2);
}

.page-gallery {
  margin-bottom: 3rem;

  //-------------------------------------------------reset*/

  .main {
    margin-top: 4rem;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 55;
    @include bp($point_6) {
      margin-top: 7rem;
    }
  }

  .loading-wrapper,
  button,
  .button-wrapper {
    font-family: rye;
    margin: 1rem;
    margin-left: 2rem;
    color: #fff;
    text-align: center;
    @include bp($point_6) {
      font-size: 3rem;
      margin-top: 1.5rem;
    }
  }
  .sort-wrapper {
    display: none;
    width: auto;

    text-align: right;
    margin-right: 1.5rem;
    margin-bottom: 3rem;
    z-index: 999999999;
    position: absolute;
    top: -2rem;
    right: -1px;
    font-size: 1.2rem;
    @include bp($point_1) {
      top: 0rem;
    }
    //margin-top: -1rem;
    @include bp($point_6) {
      margin-top: 2rem;
    }
  }
  .ui.compact.menu,
  .ui.menu {
    background-color: #ccc;
    border: 1px solid #666;
    @include bp($point_1) {
      font-size: 1.6rem;
    }
    @include bp($point_5) {
      font-size: 2rem;
    }
  }
  .text {
    font-size: 1.3rem;
    @include bp($point_1) {
      font-size: 1.6rem;
    }
    @include bp($point_5) {
      font-size: 2rem;
    }
  }
  .ui.menu .item {
    padding: 10px 10px !important;
  }
  .ui.simple.dropdown .menu {
    right: -1px;
    left: auto;
  }
  .image-gallery {
    z-index: 9;
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: left;
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-template-columns: repeat(auto-fill, 240px);
    justify-content: center;
    justify-items: center;
    gap: 10px;
    margin: 0 auto;
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 3rem;
    margin &:after {
      content: '';
      display: inline-block;
    }

    @include bp($point_5) {
      grid-template-columns: repeat(auto-fill, 350px);
      padding: 0 1.5rem;
      margin-top: 3rem;
      // display: block;
    }
    @include bp($point_6) {
      grid-template-columns: repeat(auto-fill, 350px);
      padding: 0 1.5rem;
      margin-top: 3rem;
      // display: block;
    }
    @include bp($point_7) {
      grid-template-columns: repeat(auto-fill, 280px);
      padding: 0 1.5rem;
      margin-top: 3rem;
      // display: block;
    }
  }

  .image-gallery > li {
    position: relative;
    cursor: pointer;
    background-color: #efefef;
    color: #666;
    z-index: 99999;
    padding: 7px;
    border-radius: 5px;
    border-radius: 5px;
    border: 2px solid #666;
    outline: 2px solid #fff;
    width: 230px;
    margin: 0 5px 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    @include bp($point_5) {
      width: 93%;
      margin-left: 3%;
      margin-top: 1.5rem;
      // min-height: 315px;
    }
    @include bp($point_6) {
      margin-bottom: 0rem;
    }
  }

  .image-gallery::after {
    content: '';
    flex-basis: 200px;
    @include bp($point_6) {
      width: 100%;
    }
  }

  .image-gallery li img {
    object-fit: cover;
    max-width: 100%;
    z-index: -1;
    position: relative;
    height: auto;
    vertical-align: middle;

    position: relative;
  }
  /* [1] The container */
  .img-hover-zoom {
    height: 205px; /* [1.1] Set it as per your need */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
    @include bp($point_5) {
      height: 320px;
    }
    @include bp($point_7) {
      height: 247px;
    }
  }

  /* [2] Transition property for smooth transformation of images */
  .img-hover-zoom img {
    transition: transform 0.5s ease;
  }

  /* [3] Finally, transforming the image when container gets hovered */
  .img-hover-zoom:hover img {
    transform: scale(1.5);
  }
  .skeleton-card span i {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    object-fit: cover;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    display: block;
    width: 230px;
    height: 230px;
    @include bp($point_6) {
      width: 100%;
      height: 260px;
    }
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  .overlay {
    position: absolute;
    width: 194px;
    height: 187px;
    background: rgba(57, 57, 57, 0.502);
    top: 16px;
    left: 16px;
    transform: scale(0);
    transition: all 0.2s 0.1s ease-in-out;
    color: #fff;
    // border-radius: 5px;
    /* center overlay text */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include bp($point_6) {
      width: 92%;
      height: 92%;
      top: 13px;
      left: 14px;
    }
    span {
      font-size: 2.4rem;
      @include bp($point_6) {
        font-size: 3rem;
      }
    }
  }

  /* hover */
  .image-gallery li:hover .overlay {
    transform: scale(1.1);
  }

  p.sig {
    overflow: hidden;
    display: block;
    margin-top: 0.5rem;
    margin-bottom: -0.3rem;
    @include bp($point_1) {
      margin-top: 0.7rem;
    }
    @include bp($point_6) {
      margin-top: 1rem;
      margin-bottom: -0.6rem;
      font-size: 2.5rem;
    }
  }

  p.sig span.first {
    float: left;
    position: relative;
    padding-left: 0.3rem;
  }

  p.sig span.second {
    float: right;
    position: relative;
    padding-right: 0.5rem;
  }
  .odometer-digit-spacer {
    margin-bottom: -1rem !important;
  }
  .odometer-wrapper {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 3rem;
    @include bp($point_6) {
      margin-top: 2rem;
      font-size: 6rem;
    }
    @media screen and (min-width: 1600px) {
      font-size: 35px;
    }

    .clowns-generated {
      margin-left: 0.8rem;
      vertical-align: middle;
      color: #ccc;
      text-transform: uppercase;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #fff;
      div {
        display: inline-block;
      }
      @include bp($point_6) {
        display: block;
        margin-top: 1.5rem;
        font-size: 4rem;
      }
      @media screen and (min-width: 1600px) {
        font-size: 34px;
      }
      span {
        color: #fff;
      }
    }
  }
  .odometer-container {
    display: inline-block;
    width: auto;
    padding: 1rem 0rem;
  }
  .odometer {
    padding: 1rem;
  }
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-slot-machine {
    background: none;
    background-color: transparent;
    border: 0;
  }
  .odometer.odometer-auto-theme .odometer-digit,
  .odometer.odometer-theme-slot-machine .odometer-digit {
    padding: 0.1em 0.3em 0;
  }
}
.ui.dropdown > .text,
.ui.menu {
  font-family: 'roboto' !important;
  font-size: 1.3rem;
  @include bp($point_6) {
    font-size: 2.2rem !important;
  }
}
.divider.text span {
  color: #881b1b;
  font-family: 'roboto';
  display: inline-block;
  margin-left: 6px;
  margin-top: 0px;
  @include bp($point_6) {
    display: none;
  }
}
.ui.menu .item > i.dropdown.icon {
  margin-top: 0px !important;
}
