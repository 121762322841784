@use 'sass:math';

$font_size_base: 1.6rem;

$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $white;

@mixin bp($width, $value: max) {
  @if $value == min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else if $value == max {
    @media screen and (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: $width) and (min-width: $value) {
      @content;
    }
  }
}

@function math($val_1, $val_2) {
  @return math.div($val_1, $val_2);
}

.header-wrapper {
  @include bp($point_6) {
    background-color: #000;
    .count {
      background: #881b1b !important;
    }
    .links {
      margin-top: 5px;
    }
  }
}
.page-merch {
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;
  .header-wrapper {
    z-index: 9;
    position: inherit;
  }
  .header-wrapper.active {
    z-index: 99;
    position: fixed;
  }
  .main {
    overflow: hidden;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5rem;
    margin-top: 1rem;
    // margin-top: 2rem;
    // opacity: 1;
    // position: absolute;
    // top: 0;
    // width: 100%;
    // z-index: 55;
    // @include bp($point_6) {
    //   margin-top: -24px;
    // }
    @include bp($point_6) {
      padding-top: 20px;
    }
  }

  .merch-wrapper {
    background-color: #fff;
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    margin-bottom: 6rem;
    border-radius: 0 0 5px 5px;

    // border: 2px solid #666;
    // outline: 2px solid #fff;
    @media screen and (min-width: 1600px) {
      max-width: 800px;
    }
    a {
      color: #000;
    }
    h2 {
      font-family: 'rye';
      color: #8e2322;
      @include bp($point_6) {
        font-size: 3.2rem;
      }
    }
    hr {
      border: 0;
      border-top: 1px solid #dddcdc;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    .quote {
      // border-top: 1px solid #ccc;
      // border-bottom: 1px solid #ccc;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-top: 3rem;
      margin-bottom: 3.5rem;
      font-size: 1.8rem;
      font-family: 'rye';
      text-align: left;
      background-color: #efefef;
      color: #999;
      padding-left: 1rem;
      @include bp($point_4) {
        font-size: 2.2rem;
      }
      @include bp($point_6) {
        font-size: 2.6rem;
        margin-top: 4rem;
        margin-bottom: 5rem;
        padding: 1.5rem 2rem;
      }
      @media screen and (min-width: 1600px) {
        font-size: 22px;
      }
    }
    img {
      width: 100%;
      padding-bottom: 0;

      &.ss {
        border: 0px solid #666;
      }
      &.coins {
        margin-top: 3rem;
        width: 60%;
        float: right;
        padding-right: 2rem;
        @include bp($point_6) {
          float: none;
          clear: both;
          display: block;
          width: 80%;
          margin: 0 auto;
          padding-left: 3rem;
          min-height: auto;
          padding-top: 2rem;
        }
      }
      @include bp($point_6) {
        min-height: 155px;
      }
    }
    @include bp($point_4) {
      margin: 0rem;
      padding: 0 3rem;
      margin-bottom: 0rem;
      padding-top: 4rem;
      border-radius: 0;
      padding-bottom: 10rem;
    }
  }
}
.page-merch::after {
  background: #000;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}
.page-merch.open-flyout::after {
  opacity: 0.3;
  z-index: 1;
}
.game-description {
  // line-height: 2.5rem;
  // font-size: 1.7rem;
  font-weight: normal;
  padding-right: 4rem;
  color: #000;
  padding: 2rem;

  padding-bottom: 6rem;
  @include bp($point_5) {
    padding-right: 2rem;
    margin-top: 1rem;
  }
  .play-wrapper {
    overflow: hidden;
    vertical-align: middle;
  }
  .game-title {
    float: left;
    @include bp($point_6) {
      padding-top: 0.7rem;
    }
  }
  .play-now {
    float: left;
    margin-left: 1rem;
    margin-top: 0rem;
    margin-bottom: 1.8rem;
    display: block;

    color: #fff;

    @include bp($point_5) {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      margin-left: 1.5rem;
    }
    a {
      border-radius: 5px;
      display: inline-block;
      background-color: #000;
      padding: 5px 9px;
      color: #ccc;
      text-decoration: none;
      font-size: 1.2rem;
      //text-transform: uppercase;
      @include bp($point_5) {
        font-size: 2.2rem;
      }
      &:hover {
        color: #fff;
      }
    }
    i {
      margin-right: 0.3rem;
      font-size: 1.4rem;
      @include bp($point_5) {
        font-size: 2.2rem;
      }
    }
  }
}

#unity-container {
  width: 100%;
  // max-width: 800px;
  margin: 0 auto;
}
#pleaserotate-backdrop {
  background-color: white;
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 100%;

  color: #fff;
  background-color: #000;
}

#pleaserotate-container {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#pleaserotate-graphic {
  margin-left: 50px;
  width: 200px;
  animation: 2s ease 0s infinite normal none running pleaserotateframes;
  transform-origin: 50% 50%;
}
#pleaserotate-graphic {
  fill: #fff;
}

@keyframes pleaserotateframes {
  0% {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  49% {
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
  100% {
    transform: rotate(90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
}

.giveaway-wrapper {
  overflow: hidden;
  margin-top: 2.5rem;
  @include bp($point_5) {
    margin-top: 3.5rem;
  }

  .giveaway-text {
    float: left;
    width: 70%;
    padding-left: 3.5rem;
  }
  .second {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
.keychain-wrapper {
  float: left;
  width: 30%;
}
img.keychain {
  max-width: 200px;
  transform: rotate(-4deg);
  margin-bottom: 3rem;
  min-height: auto !important;
}

.container .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  width: 100%;

  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;

  color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  i {
    font-size: 5rem;
    margin-bottom: 0.6rem;
  }
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}
.page-merch .grid-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  justify-content: flex-start;
  li {
    width: 100%;
    max-width: calc(33.3333% - 15px);
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      max-width: none;
      width: 100%;
    }

    .product {
      border: 1px solid #ccc;
      padding: 1rem;
      p {
        margin-bottom: 14px;
      }
    }
    .product-grid-tile {
      margin: 5px 0 5px 0;
      font-size: 26px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    .product-grid-price {
      font-family: 'roboto';
      font-size: 18px;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .product-desc-wrapper {
      margin-top: 2rem;
      @media screen and (max-width: 767px) {
        padding: 7px;
      }
      h3 {
        display: none;
      }
      .product-desc {
        font-size: 15px;
        @media screen and (max-width: 767px) {
          margin-top: 1.5rem;
        }
      }
    }
    .slick-prev,
    .slick-next {
      display: none !important;
    }
    .slick-dots {
      bottom: -28px;
      width: 92%;
      text-align: center;
      display: block;
      @media screen and (max-width: 767px) {
        bottom: -28px;
      }
      li {
        width: 5px;
      }
    }
    input.addto_cart_btn {
      border: 0.03em solid #000;
      border-radius: 0.34em;
      color: #333;
      font-family: roboto;
      font-size: 16px;
      line-height: 1.35em;
      margin: 5px 0 15px;
      min-height: 35px;
      // min-width: 110px;
      padding: 5px 10px;
      text-transform: uppercase;
      transition: 0.5s;
      cursor: pointer;
      position: relative;
      background-color: #fff;
      @media screen and (max-width: 767px) {
        min-width: none;
        font-size: 14px;
        padding: 2px 8px;
      }
    }
    input.addto_cart_btn:before {
      background-color: #005d68;
      bottom: 0;
      color: #333;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      transition: all 0.3s;
      width: 0;
      z-index: -1;
    }
    input.addto_cart_btn:after {
      background-color: #fff;
      bottom: 0;
      color: #000;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      transition: all 0.3s;
      width: 100%;
      z-index: -2;
      border: 0.03em solid #000;
      border-radius: 0.34em;
    }
    input.addto_cart_btn:hover {
      background-color: #005d68;
      color: #fff;
      border-color: #005e68;
    }
    input.preorder-btn {
      background-color: #efefef;
    }
  }
}
.top-merch-banner {
  text-align: center;
  padding-bottom: 1.5rem;
  margin-top: -2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  background-color: #000;
  color: #fff;
  padding-top: 1.5rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 767px) {
    font-size: 15px;
    margin-top: -4rem;
    margin-left: -3rem;
    margin-right: -3rem;
    margin-bottom: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    span {
      display: block;
      text-align: center;
      &:first-child {
        padding-bottom: 7px;
      }
    }
  }
}
.merch-top-desc {
  display: block;
  clear: both;
  margin-bottom: 2.5rem;
  p {
    width: 100%;
    margin-bottom: 1.5rem;
    @include bp($point_6) {
      width: 100%;
      margin-bottom: 4.5rem;
    }
  }
}
@media screen and (min-width: 576px) {
  .cart-icon,
  .merch-wrapper.grid-wrapper {
    max-width: 540px;
  }
}
@media screen and (min-width: 768px) {
  .cart-icon,
  .merch-wrapper.grid-wrapper {
    max-width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .cart-icon,
  .merch-wrapper.grid-wrapper {
    max-width: 960px;
  }
}
@media screen and (min-width: 1040px) {
  .cart-icon,
  .merch-wrapper.grid-wrapper {
    max-width: 1000px;
  }
}
@media screen and (min-width: 1200px) {
  .cart-icon,
  .merch-wrapper.grid-wrapper {
    max-width: 1180px;
  }
}
@media screen and (min-width: 1500px) {
  .cart-icon,
  .merch-wrapper.grid-wrapper {
    max-width: 800px;
  }
}
.cart-wrapper {
  display: inline-block;
  margin-top: -6px;
  position: relative;
}
.cart-wrapper.active {
  .count {
    background-color: #881b1b;
  }
}
.cart-icon {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
  margin: 0px auto;
  margin-top: 15px;
  position: relative;
  z-index: 9;
  padding-right: 2rem;
  cursor: pointer;
  .count {
    width: 25px;
    height: 25px;
    background: #000;
    color: #fff;
    text-align: center;
    font-size: 12px;
    line-height: 25px;
    border-radius: 50px;
    position: absolute;
    top: -14px;
    right: 0px;
    font-family: roboto;
    @include bp($point_6) {
      width: 21px;
      height: 21px;
    }
  }
}
.active {
  .cart-icon {
    @include bp($point_6) {
      margin-top: 13px;
    }
  }
  .cart-icon .at-icon {
    @include bp($point_6) {
      width: 20px;
    }
  }
}
.cart-icon .at-icon {
  width: 24px;
  fill: #fff;
  @include bp($point_6) {
    width: 20px;
  }
}
.svg.icon.icon-ui-cart {
  width: 24px;
}

/* Basic styling for the flyout cart container */
.flyout-cart {
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // min-width: 440px;
  padding: 20px;
  position: fixed;
  right: 0;
  z-index: 110; /* Ensure the cart appears above other elements */
  top: 0;
  height: 100%;
  transition: all 0.2s ease-in-out;
  transform: translate(100%);
}
.flyout-cart.active {
  transform: translate(0);
}
/* Style for the cart items list */
.flyout-cart ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.flyout-cart li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 8px 0;
}

/* Style for the "Your cart is empty" message */
.flyout-cart p {
  margin: 10px 0;
}

/* Style for the remove button */
.flyout-cart .checkout button {
  background-color: #333;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  padding: 12px 10px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  z-index: 9;
}
.flyout-cart .checkout button:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  color: #000;
  background-color: #005d68;
  transition: all 0.3s;
  z-index: -1;
}
.flyout-cart .checkout button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -2;
}
.flyout-cart .checkout button:hover:before {
  width: 100%;
}
.flyout-cart {
  transform: translate(100%);
  color: #333;
  min-width: 340px;
  @include bp($point_6) {
    min-width: auto;
  }
  h2 {
    font-size: 18px;
    margin-right: 2rem;
  }
  p {
    margin: 0 0 10px 0;
  }
  ul {
    li {
      margin-bottom: 20px;
      padding: 8px 0 15px 0px;
      .cart-item {
        display: flex;
        .cart-image {
          margin-right: 20px;
          img {
            width: 78px;
          }
        }
        .cart-details {
          p {
            text-align: left;
          }
          .quantity {
            display: inline-flex;
            border: 1px solid #ddd;
            border-radius: 6px;
            overflow: hidden;
            button {
              line-height: 19px;
              width: 42px;
              height: 42px;
              padding: 4px 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0;
              background: #fff;
              border-radius: 0;
              color: #fff;
              color: #000;
              font-size: 25px;
              cursor: pointer;
            }
            span {
              line-height: 19px;
              width: 42px;
              height: 42px;
              padding: 4px 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0;
              background: #fff;
              border-radius: 0;
              font-size: 14px;
              border-left: 1px solid #ddd;
              border-right: 1px solid #ddd;
              font-family: roboto;
            }
          }
          .remove-btn {
            width: 25px;
            cursor: pointer;
            margin-left: 10px;
            svg.at-icon {
              width: 16px;
            }
          }
        }
      }
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    span {
      font-size: 14px;
    }
  }
  .checkout-message {
    margin-bottom: 20px;
    p {
      font-size: 14px;
      text-align: left;
    }
  }
}
.main-close-cart {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
  span.close-cart svg {
    width: 16px;
    position: relative;
    top: -7px;
  }
}
.main-checkout {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px 20px 40px 20px;
}
.product {
  h3 {
    margin-bottom: 0.5rem;
  }
}
.product-desc {
  font-size: 18px;
  padding: 0 0 10px 0px;
  font-family: roboto;
}
.ctdrawer {
  top: 0;
  right: 0;
  visibility: hidden;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
}

.cart-black-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: #000000bf;
}

.cart-black-overlay.active {
  display: block;
}
