$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

@mixin bp($width, $value: max) {
  @if $value == min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else if $value == max {
    @media screen and (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: $width) and (min-width: $value) {
      @content;
    }
  }
}

.header-wrapper {
  font-family: 'rye';
  padding: 1.5rem 1rem 1.5rem 1rem;
  // background-color: #000;
  color: #ccc;
  z-index: 999;
  text-align: center;
  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  font-size: 2.7rem;
  display: flex;
  justify-content: center;
  gap: 24px;
  .links {
    margin-top: 3px;
  }
  @media screen and (min-width: 1600px) {
    font-size: 38px;
  }
  &.active {
    background-color: #000;
    font-size: 2.3rem;
    .links {
      margin-top: 5px;
    }
    @include bp($point_6) {
      font-size: 3.4rem;
    }
    .language-wrapper select {
      @include bp($point_6) {
        font-size: 1.4rem;
      }
    }
  }

  @include bp($point_3) {
    font-size: 2.7rem;
    padding: 1.5rem 1rem;
    text-align: left;
    padding-left: 3rem;
  }
  @include bp($point_4) {
    font-size: 2rem;
    padding: 2.5rem 1rem;
    text-align: left;
    padding-left: 1.5rem;
  }
  @include bp($point_6) {
    font-size: 4rem;
    padding: 2rem 1rem;
    text-align: left;
    padding-left: 3rem;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #dd9e44;
    }
  }
  .merch-link {
    color: #ccc;
  }
  .language-wrapper {
    display: none;
    color: #ccc;
    font-size: 1.2rem;
    position: absolute;
    right: 1.5rem;
    top: 1.3rem;
    font-family: 'roboto';
    select {
      padding: 5px 5px;
      font-size: 1.3rem;
      background-color: #000;
      color: #ccc;
      @include bp($point_6) {
        font-size: 1.8rem;
        margin-top: 1px;
      }
    }
    @include bp($point_3) {
      font-size: 2rem;
      right: 8px;
      top: 9px;
    }
    @include bp($point_6) {
      font-size: 2rem;
      right: 8px;
      top: 10px;
    }
    a {
      text-decoration: underline !important;
      color: #ccc;
      &:hover {
        color: #fff;
      }
    }
  }
}
