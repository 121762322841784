@use 'sass:math';

@font-face {
  font-family: rye;
  src: url(../../fonts/Rye-Regular.ttf);
}

//-------------------------------------------------reset*/

$font_1: 'rye', sans-serif;
$font_size_base: 1.6rem;

$point_1400: 1400px;
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $black;

@mixin bp($width, $value: max) {
  @if $value == min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else if $value == max {
    @media screen and (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: $width) and (min-width: $value) {
      @content;
    }
  }
}

@function math($val_1, $val_2) {
  @return math.div($val_1, $val_2);
}

.stripe-form-wrapper {
  font-family: 'roboto';
  font-size: 1.2rem;
  @include bp($point_6) {
    font-size: 2rem;
  }
  .button-wrapper {
    display: block;
  }
  button {
    font-size: 1.4rem;
    padding: 8px 12px;
    display: inline-block;
    margin-top: 1rem;
    background-color: green;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    @include bp($point_6) {
      font-size: 2.5rem;
      margin-top: 2rem;
    }
    &.cancel_btn {
      background-color: #962726;
      margin-left: 0.75rem;
    }
  }
  .StripeElement {
    margin-bottom: 1.5rem;
  }
}
.pay-up {
  font-family: 'roboto';
  font-weight: bold;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
  @include bp($point_6) {
    font-size: 2.7rem;
  }
}
a.view-clown {
  margin-top: 2rem !important;
  width: 80%;
  padding-top: 1rem;
}
.share {
  margin-top: 1rem;
}
.superclown-wrapper .superclown-screen .payment-social {
  display: inline-block;
  margin-top: 0.5rem;
  @include bp($point_6) {
    margin-top: 0.5rem;
  }
  a {
    font-size: 3rem;
    display: inline-block;
    margin: 0;
    padding: 0;
    background-color: transparent;
    @include bp($point_6) {
      font-size: 4rem;
    }
    .fa-brands {
      width: 3rem;
      @include bp($point_6) {
        width: 4.5rem;
      }
      &.fa-square-twitter {
        margin-right: 0.5rem;
        @include bp($point_6) {
          margin-right: 1rem;
        }
      }
      &.fa-square-facebook {
        margin-right: 0.5rem;
      }
      &.fa-link-horizontal {
        margin-bottom: 2px;
        background-color: #ccc;
        padding: 0.75rem 0.5rem 0.75rem 0.5rem;
        border-radius: 5px;
      }
    }
  }
}
