@use 'sass:math';

$font_size_base: 1.6rem;

$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $white;

@mixin bp($width, $value: max) {
  @if $value == min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else if $value == max {
    @media screen and (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: $width) and (min-width: $value) {
      @content;
    }
  }
}

@function math($val_1, $val_2) {
  @return math.div($val_1, $val_2);
}

.header-wrapper {
  @include bp($point_6) {
    background-color: #000;
  }
}
.page-home,
.page-home-modal {
  margin-bottom: 3rem;
  margin-top: 2rem;
  @media (min-width: 1763px) {
    margin-top: 1rem;
  }
  .main {
    opacity: 1;
    top: 0;
    width: 100%;
    z-index: 55;
    @include bp($point_6) {
      margin-top: -24px;
    }
  }

  .home-wrapper {
    background-color: #fff;
    max-width: 700px;
    margin: 0 auto;
    padding: 0rem;
    padding-top: 0;
    margin-bottom: 6rem;
    border-radius: 0 0 6px 6px;

    // border: 2px solid #666;
    // outline: 2px solid #fff;
    @media screen and (min-width: 1600px) {
      max-width: 800px;
    }
    @include bp($point_6) {
      padding: 0 !important;
    }
    a {
      color: #000;
    }
    h2 {
      font-family: 'rye';
      color: #8e2322;
      @include bp($point_6) {
        font-size: 3.2rem;
      }
    }

    hr {
      border: 0;
      border-top: 1px solid #dddcdc;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    .quote {
      // border-top: 1px solid #ccc;
      // border-bottom: 1px solid #ccc;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-top: 3rem;
      margin-bottom: 3.5rem;
      font-size: 1.8rem;
      font-family: 'rye';
      text-align: left;
      background-color: #efefef;
      color: #999;
      padding-left: 1rem;
      @include bp($point_4) {
        font-size: 2.2rem;
      }
      @include bp($point_6) {
        font-size: 2.6rem;
        margin-top: 4rem;
        margin-bottom: 5rem;
        padding: 1.5rem 2rem;
      }
      @media screen and (min-width: 1600px) {
        font-size: 22px;
      }
    }
    img {
      width: 100%;
      padding-bottom: 0;

      &.ss {
        border: 0px solid #666;
      }
      &.coins {
        margin-top: 3rem;
        width: 60%;
        float: right;
        padding-right: 2rem;
        @include bp($point_6) {
          float: none;
          clear: both;
          display: block;
          width: 80%;
          margin: 0 auto;
          padding-left: 3rem;
          min-height: auto;
          padding-top: 2rem;
        }
      }
      @include bp($point_6) {
        //min-height: 155px;
        margin-top: 1rem;
        margin-bottom: 2rem;
        width: 25px !important;
        margin-right: 1.2rem;
      }
    }
    @include bp($point_4) {
      margin: 0rem;
      padding: 0 3rem;
      margin-bottom: 0rem;
      padding-top: 4rem;
      border-radius: 0;
    }
  }

  .odometer-digit-spacer {
    margin-bottom: -1rem !important;
  }
  .odometer-wrapper {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 0rem;
    margin-bottom: 0rem;
    font-size: 4rem;
    @include bp($point_6) {
      margin-top: 2rem;
      font-size: 6rem;
    }
    @media screen and (min-width: 1600px) {
      font-size: 4rem;
    }

    .clowns-generated {
      margin-left: 0.8rem;
      vertical-align: middle;
      color: #ccc;
      text-transform: uppercase;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #fff;
      div {
        display: inline-block;
      }
      @include bp($point_6) {
        display: block;
        margin-top: 1.5rem;
        font-size: 4rem;
      }
      @media screen and (min-width: 1600px) {
        font-size: 34px;
      }
      span {
        color: #fff;
      }
    }
  }
  .odometer-container {
    display: inline-block;
    width: auto;
    padding: 1rem 0rem;
  }
  .odometer {
    padding: 1rem;
  }
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-slot-machine {
    background: none;
    background-color: transparent;
    border: 0;
  }
  .odometer.odometer-auto-theme .odometer-digit,
  .odometer.odometer-theme-slot-machine .odometer-digit {
    padding: 0.1em 0.3em 0;
  }

  .ui.dropdown > .text,
  .ui.menu {
    font-family: 'roboto' !important;
    font-size: 1.3rem;
    @include bp($point_6) {
      font-size: 2.2rem !important;
    }
  }
  .divider.text span {
    color: #881b1b;
    font-family: 'roboto';
    display: inline-block;
    margin-left: 6px;
    margin-top: 0px;
    @include bp($point_6) {
      display: none;
    }
  }
  .ui.menu .item > i.dropdown.icon {
    margin-top: 0px !important;
  }
}
.page-home-modal {
  margin-top: 0;
}
.modal-wrapper-home {
  padding: 0px 20px 20px 20px;
}
.home-description {
  // line-height: 2.5rem;
  // font-size: 1.7rem;
  font-weight: normal;
  padding-right: 4rem;
  color: #000;
  padding: 0rem;

  padding-bottom: 6rem;
  @include bp($point_5) {
    padding-right: 0rem;
    margin-top: 0rem;
  }
  .play-wrapper {
    overflow: hidden;
    vertical-align: middle;
  }
  .home-title {
    color: #8e2322;
    text-align: center;
    margin-bottom: 0;
    @include bp($point_6) {
      padding-top: 0.7rem;
    }
  }
  .goal-meter {
    text-align: center;
    margin-bottom: 3.5rem;
    font-size: 2rem;
  }
  .play-now {
    float: left;
    margin-left: 1rem;
    margin-top: 0rem;
    margin-bottom: 1.8rem;
    display: block;

    color: #fff;

    @include bp($point_5) {
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      margin-left: 1.5rem;
    }
    a {
      border-radius: 5px;
      display: inline-block;
      background-color: #000;
      padding: 5px 9px;
      color: #ccc;
      text-decoration: none;
      font-size: 1.2rem;
      //text-transform: uppercase;
      @include bp($point_5) {
        font-size: 2.2rem;
      }
      &:hover {
        color: #fff;
      }
    }
    i {
      margin-right: 0.3rem;
      font-size: 1.4rem;
      @include bp($point_5) {
        font-size: 2.2rem;
      }
    }
  }
}

#unity-container {
  width: 100%;
  // max-width: 800px;
  margin: 0 auto;
}
#pleaserotate-backdrop {
  background-color: white;
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 100%;

  color: #fff;
  background-color: #000;
}

#pleaserotate-container {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#pleaserotate-graphic {
  margin-left: 50px;
  width: 200px;
  animation: 2s ease 0s infinite normal none running pleaserotateframes;
  transform-origin: 50% 50%;
}
#pleaserotate-graphic {
  fill: #fff;
}

@keyframes pleaserotateframes {
  0% {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  49% {
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
  100% {
    transform: rotate(90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
}
.accordion,
.accordion p {
  @media (min-width: 1763px) {
    font-size: 1rem;
  }
}
.accordion {
  margin-bottom: 2rem;
}
.accordion__button {
  font-family: 'roboto';
  padding: 6px;
  padding-left: 10px;

  @include bp($point_6) {
    font-size: 2.5rem;
  }
}
.accordion__panel {
  padding: 8px 8px 8px 28px;
}
.goal-text {
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
  @media (min-width: 1763px) {
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
    margin-top: 0rem;
  }
  span {
    color: #ff9e2c;
    font-size: 2rem;
    @media (min-width: 1763px) {
      font-size: 1.4rem;
    }
    @include bp($point_6) {
      font-size: 2.3rem;
    }
  }
  @include bp($point_6) {
    font-size: 2.3rem;
    margin: 2rem 0 2rem 0;
  }
}
.social-wrapper {
  margin-top: 1rem;
}
.fa-brands {
  width: 3.5rem !important;
  margin-right: 0.5rem;
  @media (min-width: 1763px) {
    width: 2.3rem !important;
  }
}
br {
  @media (min-width: 1763px) {
    display: block;
    padding-bottom: -1rem;
  }
}
.art-rendering {
  color: #999;
  text-align: right;
  font-size: 1.35rem;
  margin-top: 0.2rem;
  @media (min-width: 1763px) {
    font-size: 0.9rem;
  }
  @include bp($point_6) {
    font-size: 2.2rem;
  }
}

.giveaway-wrapper {
  overflow: hidden;
  margin-top: 0rem;
  @include bp($point_5) {
    margin-top: 2rem;
  }
  h1 {
    color: #fff;

    margin-top: 0rem;
    margin-bottom: 0.5rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    font-size: 3.8rem;
    padding-left: 7rem;
    @media (min-width: 1763px) {
      font-size: 2rem;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }
    @include bp($point_6) {
      font-size: 5rem;
      padding: 7rem 2rem 7rem 12.5rem;
    }
  }
  .top-wrapper {
    overflow: hidden;
    background-color: #ff9e2c;
    background-image: url(../../scss/statue-header.png);
    background-repeat: no-repeat;
    background-position: left -30px;
    margin-bottom: 1rem;
    @include bp($point_6) {
      background-position: -43px -6px;
      background-size: 97%;
    }
  }
  .giveaway-text {
    float: left;
    width: 50%;
    padding-left: 3.5rem;
    @include bp($point_6) {
      float: none;
      width: 100%;
      padding-left: 0;
      margin-top: 4rem;
    }
  }
  .second {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
}
.keychain-wrapper {
  float: left;
  width: 50%;
  @include bp($point_6) {
    float: none;
    width: 100%;
  }
}
img.keychain {
  max-width: 200px;
  transform: rotate(-4deg);
  margin-bottom: 3rem;
  min-height: auto !important;
}

.container .title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  width: 100%;

  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: center;

  color: #fff;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  i {
    font-size: 5rem;
    margin-bottom: 0.6rem;
  }
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}
.odometer-formatting-mark {
  display: none;
}
.odometer-digit {
  margin-left: 0.15em;
}
.ph_meter {
  //background: lightgrey;
  width: 100%;
  padding: 0 0rem;
  display: block;
  margin-bottom: 1rem;
  height: 6rem;
}
.ph_meter:-moz-meter-optimum::-moz-meter-bar {
  background: indianred;
}

.generate-link {
  font-family: 'roboto' !important;
}
.close-btn {
  cursor: pointer;
  display: block;
  float: right;
  font-size: 2.5rem;
  margin-top: -1rem;
  position: absolute;
  right: 10px;
  top: 1.5rem;
  @media (min-width: 1763px) {
    font-size: 1.9rem;
    top: 1.5rem;
  }
  @include bp($point_6) {
    font-size: 5rem;
  }
}

// Progress Bars
progress,
progress[role] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-size: auto;
  height: 30px;
  width: 100%;
}

// The unordered list
.skill-list {
  list-style: none;
  margin: 0;
  padding: 0em 0 1em;
  @media (min-width: 1763px) {
    padding-bottom: 0;
  }
  @include bp($point_6) {
    margin-bottom: 2rem;
  }
}

// The list item
.skill {
  margin-bottom: 1em;
  position: relative;
  h3 {
    color: #fff;
    left: 10px;
    position: absolute;
    //top: 5px;
    top: 20%;
    padding-left: 5px;
    font-size: 1.3rem;
    font-family: 'roboto';
    margin-bottom: 0;
    @media (min-width: 1763px) {
      font-size: 1rem;
    }
    @include bp($point_6) {
      top: 6px;
      font-size: 2.4rem;
    }
    span {
      //color: #ccc;
    }
  }
  ::-webkit-progress-value {
    -webkit-animation: bar-fill 2s;
    width: 0px;
  }
}

// Style the bar colors
.skill-1::-webkit-progress-value {
  background: #ff9e2c;
}

.skill-1::-moz-progress-bar {
  background: #ff9e2c;
}

// Animation Keyframes
@-webkit-keyframes bar-fill {
  0% {
    width: 0;
  }
}

@keyframes bar-fill {
  0% {
    width: 0;
  }
}
