$radius: 5px;

@use 'sass:math';

@font-face {
  font-family: rye;
  src: url(../../fonts/Rye-Regular.ttf);
}
@font-face {
  font-family: survivor;
  src: url(../../fonts/Survivor-Wood.otf);
}

//-------------------------------------------------reset*/

$font_1: 'rye', sans-serif;
$font_size_base: 1.6rem;

$point_1400: 1400px;
$point_1: 1200px;
$point_1199: 1199px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 767px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $black;

@mixin bp($width, $value: max) {
  @if $value ==min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else if $value ==max {
    @media screen and (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: $width) and (min-width: $value) {
      @content;
    }
  }
}

@function math($val_1, $val_2) {
  @return math.div($val_1, $val_2);
}

@font-face {
  font-family: roboto;
  src: url(../../fonts/Roboto-Regular.ttf);
}
.radio-wrapper {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @include bp($point_6) {
    margin-top: 2.5rem;
  }
  .input-holder {
    // margin: 2em auto 0;
    display: flex;
    width: 95%;
    justify-content: space-between;
    font-family: 'roboto';
    @include bp($point_6) {
      width: 95%;
      margin-bottom: 10px;
    }
  }

  .radio-cont {
    position: relative;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: box-shadow 0.3s;
      background-color: #efefef;
    }
    input[type='radio'] {
      display: none;
    }
    &:first-child {
      span {
        border-radius: $radius 0 0 $radius;
      }
    }
  }

  input[type='text'] {
    border: none;
    display: block;
    margin: 0;
    padding: 15px;
    border-radius: 0 $radius $radius 0;
    background-color: #fff;
  }

  .radio-cont,
  input[type='text'] {
    font-family: 'roboto';
    font-weight: 100;
    color: #000;
    padding: 1rem 0;
    width: 100%;
    text-align: center;
    border-right: 1px solid #ddd;
    height: auto;
    width: 100%;
    @include bp($point_1) {
    }
    @include bp($point_4) {
      padding: 2rem 0;
    }
    @include bp($point_6) {
      padding: 2.6rem 0;
    }
    &.other {
      padding: 0 1rem;
      width: 100%;
      @include bp($point_1) {
      }
      @include bp($point_4) {
        padding: 2rem 1rem;
      }
      @include bp($point_6) {
        padding: 2.6rem 1.5rem;
      }
    }
  }

  .radio-cont {
    input:checked {
      ~ span {
        font-weight: bold;
        color: #fff;
        &.blue {
          background-color: #528aae;
        }
        &.green {
          background-color: #95bb72;
        }
        &.red {
          background-color: #c10a36;
        }
        &.brown {
          background-color: #d4af37;
        }
      }
    }
  }

  @include bp($point_6) {
    .radio-cont,
    input[type='text'] {
      font-size: 2.5rem;
      font-weight: 400;
    }
  }
}
.nick-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  .name-input {
    margin-bottom: 1rem;
  }
  input {
    //float: left;
  }
  .word-count-wrapper {
    margin-left: 8px;
    width: 10%;
    //float: left;
    position: absolute;
    right: 11%;
    top: 26%;
    color: #000;
    z-index: 9999999999999;
    color: #666;
    @include bp($point_1400) {
      right: 11%;
      top: 12px;
    }
    @include bp($point_2) {
      right: 11%;
      top: 12px;
    }
    @include bp($point_6) {
      font-size: 1.6rem;
      right: 11%;
      top: 12px;
    }
  }
}
