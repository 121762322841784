@use 'sass:math';

$font_size_base: 1.6rem;

$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $white;

@mixin bp($width, $value: max) {
  @if $value == min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else if $value == max {
    @media screen and (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: $width) and (min-width: $value) {
      @content;
    }
  }
}

@function math($val_1, $val_2) {
  @return math.div($val_1, $val_2);
}

.clown-wrapper {
  $font_size_base: 1.6rem;

  $point_1: 1200px;
  $point_2: 1024px;
  $point_3: 1023px;
  $point_4: 768px;
  $point_5: 640px;
  $point_6: 480px;
  $point_7: 375px;
  $point_8: 320px;

  $white: #fff;
  $black: #000;

  $color_text_base: $black;
  $color_link_base: #4aa3df;
  $body_bg: $white;

  &.modal-wrapper {
    padding-bottom: 0;
    background-color: #000;
  }
  @mixin bp($width, $value: max) {
    @if $value == min {
      @media screen and (min-width: $width) {
        @content;
      }
    } @else if $value == max {
      @media screen and (max-width: $width) {
        @content;
      }
    } @else {
      @media (max-width: $width) and (min-width: $value) {
        @content;
      }
    }
  }

  @function math($val_1, $val_2) {
    @return math.div($val_1, $val_2);
  }

  padding-bottom: 7rem;
  .loading-wrapper {
    position: absolute;
    top: 44%;
    left: 20%;
    color: #000;
    font-size: 1.9rem;
    font-family: 'rye';
    @include bp($point_1) {
      font-size: 2.4rem;
    }

    @include bp($point_6) {
      top: 44%;
      left: 14%;
      font-size: 3rem;
    }
    @media screen and (min-width: 1600px) {
      font-size: 22px;
    }
  }

  img {
    width: 100%;
    // border-bottom: 1px solid #000;

    &.swipe-image {
      filter: brightness(50%);
    }
  }

  .image-wrapper {
    margin: 0 auto;
    width: 512px;
    margin-top: 9rem;
    display: block;
    border: 2px solid #666;
    outline: 2px solid #fff;
    background-color: #fff;
    border-radius: 5px;
    padding: 1.5rem;
    @include bp($point_6) {
      width: 90%;
      margin-top: 14rem;
    }
    &.modal {
      margin-top: 0;
    }
  }
  .top-wrapper {
    overflow: hidden;
    margin-top: 1rem;
  }
  h1 {
    color: #000;
    font-weight: normal;
    font-size: 2.4rem;
    margin-bottom: 0rem;
    margin-top: 0.4rem;
    text-align: left;
    float: left;
    @include bp($point_6) {
      font-size: 3.5rem;
      margin-top: 1.3rem;
    }
    @media screen and (min-width: 1600px) {
      font-size: 24px;
    }
  }
  .details-wrapper {
    position: relative;
    background-color: #fff;
    display: inline-block;
    // border: 1px solid #000;
    //margin-bottom: 6rem;
    font-size: 1.5rem;
    width: 100%;
  }
  .loading-swipe {
    position: absolute;
    top: 35%;
    left: 35%;
    color: #fff;
    font-size: 3rem;
    z-index: 99999;
    @media screen and (min-width: 1600px) {
      font-size: 24px;
    }
  }
  .fa-brands {
    width: 3rem;
    @media screen and (min-width: 1600px) {
      width: 30px;
    }
    @include bp($point_6) {
      width: 5rem;
    }
    &.fa-square-twitter {
      margin-right: 0.5rem;
      @include bp($point_6) {
        margin-right: 1rem;
      }
    }
    &.fa-square-facebook {
      margin-right: 0rem;
    }
    &.fa-link-horizontal {
      margin-bottom: 2px;
      background-color: #ccc;
      padding: 0.75rem 0.5rem 0.75rem 0.5rem;
      border-radius: 5px;
    }
  }
  .selected-5 .details-wrapper,
  .selected-20 .details-wrapper,
  .selected-50 .details-wrapper,
  .selected-100 .details-wrapper {
    background-color: transparent;

    p,
    h2 {
      color: #000;
    }
    .signed-by-wrapper p {
      color: #fff;
      margin-top: -0.8rem;
    }
  }
  .ui.styled.accordion {
    margin-top: 2rem;
  }
  .details-wrapper-inside {
    margin-top: 0rem;
  }
  .details-wrapper-text {
    width: 100%;
    position: relative;
    padding: 1rem;
    padding-top: 0px;

    @include bp($point_6) {
      width: 100%;
      font-size: 3.2rem;
    }
    h2 {
      margin-bottom: 1rem;
      font-size: 1.8rem;
      @include bp($point_6) {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
      }
    }
    p {
      margin: 0;
      margin-bottom: 4px;
    }
  }
  .status-wrapper {
    margin: 0 auto;
    text-align: center;
    color: #ccc;
    font-size: 1.8rem;
    position: relative;
    top: 0rem;
    span {
      color: #671210;
    }
  }
  .social-wrapper {
    font-size: 3.3rem;
    width: auto;
    float: right;
    @include bp($point_6) {
      font-size: 5rem;
      top: -3px;
      right: 10px;
    }
    @media screen and (min-width: 1600px) {
      font-size: 26px;
    }
    i.fa-square-twitter {
      color: #1da1f2;
      margin-right: 6px;
    }
    i.fa-square-facebook {
      color: #3b5998;
    }
  }
  .skeleton-clown {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    object-fit: cover;
    height: 477px;
    margin-bottom: 13.7rem;
    @include bp($point_6) {
      width: 100%;
      height: 338px;
      margin-bottom: 61rem;
    }
  }
}
.point-left,
.point-right {
  position: absolute;
  background-color: transparent !important;
  border-radius: 50px;
  padding: 0rem 1rem;
  top: -25rem;
  @include bp($point_6) {
    display: none;
  }
  i {
    color: #999;
    font-size: 4.5rem;
    &:hover {
      color: #fff;
    }
  }
}
.point-left {
  left: -8rem;
}
.point-right {
  right: -8rem;
}
