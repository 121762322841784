@use 'sass:math';

//-------------------------------------------------reset*/

$font_size_base: 1.6rem;

@font-face {
  font-family: rye;
  src: url(../fonts/Rye-Regular.ttf);
}
@font-face {
  font-family: roboto;
  src: url(../fonts/Roboto-Regular.ttf);
}

$point_1400: 1400px;
$point_1: 1200px;
$point_1199: 1199px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 767px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;

$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base: #4aa3df;
$body_bg: $black;

@mixin bp($width, $value: max) {
  @if $value ==min {
    @media screen and (min-width: $width) {
      @content;
    }
  } @else if $value ==max {
    @media screen and (max-width: $width) {
      @content;
    }
  } @else {
    @media (max-width: $width) and (min-width: $value) {
      @content;
    }
  }
}

@function math($val_1, $val_2) {
  @return math.div($val_1, $val_2);
}

html {
  height: 100%;
  font-weight: 400;
  font-family: 'rye';
  line-height: 1.33;
  @include bp(1024px, min) {
    font-size: calc(100vw / 2000 * 14);
  }

  @media (min-width: $point_6) and (max-width: $point_3) {
    font-size: calc(100vw / 1024 * 10);
  }

  @include bp($point_6) {
    font-size: calc(100vw / 768 * 10.5);
  }
}

body {
  position: relative;

  display: flex;
  height: 100%;

  font-size: $font_size_base;
  color: $color_text_base;

  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $body_bg;
  background: url('bg.jpg') repeat !important;
  background-size: 30% !important;

  @supports (display: grid) {
    display: block;
  }
}

h1 {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-size: 4rem;
  color: #dd9e44;
  text-align: center;
  font-weight: normal;
}

h2 {
  font-size: 2rem;
  font-weight: normal;
  margin-bottom: 1rem;
  @include bp($point_6) {
    font-size: 3rem;
  }
  @media screen and (min-width: 1600px) {
    font-size: 25px;
  }
}
h3 {
  font-size: 1.7rem;
  font-weight: normal;
  margin-bottom: 1rem;
  @include bp($point_6) {
    font-size: 2.5rem;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}

p,
input,
.radio-cont span,
.input-holder .other {
  font-size: 1.5rem;
  font-family: 'roboto';

  font-weight: 400;
  @include bp($point_1400) {
    font-size: 1.6rem;
  }
  @include bp($point_4) {
    font-size: 2rem;
  }
  @include bp($point_5) {
    font-size: 2.3rem;
  }
  @include bp($point_6) {
    font-size: 2.6rem;
  }
  @include bp($point_7) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }
}

input,
.radio-cont span,
.input-holder .other {
  @media screen and (min-width: 1500px) {
    font-size: 1.4rem;
  }
}

a {
  color: #000;
  cursor: pointer;
}
.ui.popup {
  font-family: 'roboto';
  font-size: 1.3rem !important;
  padding: 0.533em 0.6em !important;

  @include bp($point_6) {
    font-size: 2.4rem !important;
  }
  @include bp($point_7) {
    font-size: 2.6rem !important;
    padding: 0.333em 0.7em !important;
  }
  background-color: #fff !important;
  i {
    color: #000;
  }
}

.ui.popup.superPopup {
  // display: none !important;
  max-width: none;
  background-color: #881b1b !important;
  background-image: url('./counter-bg.png');
  background-repeat: no-repeat;
  color: #fff;
  border: 1px solid #4f0e0e;
  margin-bottom: 0.5rem !important;

  @include bp($point_6) {
    margin-bottom: 1rem !important;
    position: absolute !important;
    top: -14px;
    right: -42px !important;
  }
  i {
    color: #fff;
    width: 12px;
    margin-right: 3px;
    @media screen and (min-width: 1600px) {
      margin-right: 9px;
    }
  }
}
.ui.top.popup.superPopup:before {
  background-color: #942320 !important;
  box-shadow: 1px 1px 0 0 #4f0e0e !important;
}
.ui.accordion .title:not(.ui) {
  font-family: 'roboto';
  font-size: 1.6rem;
  color: #999;
  font-weight: normal;
  @include bp($point_4) {
    font-size: 2rem;
  }
  @include bp($point_5) {
    font-size: 2.6rem;
  }
  @include bp($point_6) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }
}
.ui.styled.accordion .accordion .title,
.ui.styled.accordion .title {
  padding: 0.75em 0.5em;
}

.selected-5 {
  background-color: #528aae !important;

  h1 {
    color: #fff !important;
  }

  p.sig {
    color: #fff !important;
    left: auto !important;
    right: 12px !important;
  }

  .signed-by-wrapper {
    font-weight: bold;
    color: #528aae;
  }
}

.selected-20 {
  background-color: #95bb72 !important;

  h1 {
    color: #fff !important;
  }

  p.sig {
    color: #fff !important;
    left: auto !important;
    right: 12px !important;
  }

  .signed-by-wrapper {
    font-weight: bold;
    color: #95bb72;
  }
}

.selected-50 {
  background-color: #c10a36 !important;

  h1 {
    color: #fff !important;
  }

  p.sig {
    color: #fff !important;
    left: auto !important;
    right: 12px !important;
  }

  .signed-by-wrapper {
    font-weight: bold;
    color: #c10a36;
  }
}

.selected-100 {
  background-color: #d4af37 !important;

  h1 {
    color: #fff !important;
  }

  p.sig {
    color: #fff !important;
    left: auto !important;
    right: 12px !important;
  }

  .signed-by-wrapper {
    font-weight: bold;
    color: #d4af37;
  }
}
.ui.dropdown > .dropdown.icon:before {
  color: #666;
}

.ui.top.center.popup {
  transform-origin: center bottom;
  @include bp($point_6) {
    //min-width: 90px;
  }
}
.fa-duotone {
  min-width: 17px;
}
