@use 'sass:math';

.page-contact {
  margin-bottom: 3rem;
  margin-top: 72px;
  //-------------------------------------------------reset*/

  $font_size_base: 1.6rem;

  $point_1: 1200px;
  $point_2: 1024px;
  $point_3: 1023px;
  $point_4: 768px;
  $point_5: 640px;
  $point_6: 480px;
  $point_7: 375px;
  $point_8: 320px;

  $white: #fff;
  $black: #000;

  $color_text_base: $black;
  $color_link_base: #4aa3df;
  $body_bg: $white;

  @mixin bp($width, $value: max) {
    @if $value == min {
      @media screen and (min-width: $width) {
        @content;
      }
    } @else if $value == max {
      @media screen and (max-width: $width) {
        @content;
      }
    } @else {
      @media (max-width: $width) and (min-width: $value) {
        @content;
      }
    }
  }

  @function math($val_1, $val_2) {
    @return math.div($val_1, $val_2);
  }

  html {
    height: 100%;
    overflow-y: scroll;
    font-weight: 400;
    line-height: 1.33;

    @include bp(1024px, min) {
      font-size: calc(100vw / 2000 * 14);
    }

    @media (min-width: $point_6 + 1) and (max-width: $point_3) {
      font-size: calc(100vw / 1024 * 10);
    }

    @include bp($point_6) {
      font-size: calc(100vw / 768 * 10.5);
    }
  }

  body {
    position: relative;

    display: flex;
    height: 100%;

    font-size: $font_size_base;
    color: $color_text_base;

    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    opacity: 1;

    @supports (display: grid) {
      display: block;
    }
  }

  .main {
    margin-top: 2rem;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 55;
    @include bp($point_6) {
      padding: 0rem;
      margin-top: 0;
      top: -22px;
    }
  }

  .contact-wrapper {
    background-color: #fff;
    max-width: 650px;
    margin: 0 auto;
    padding: 1rem;
    margin-bottom: 8rem;
    border-radius: 5px;
    border-radius: 5px;
    border: 2px solid #666;
    outline: 2px solid #fff;
    margin-top: 0rem;
    font-family: 'roboto';
    @include bp($point_6) {
      border: 0px solid #666;
      outline: 0px solid #fff;
      border-radius: 0;
      padding: 3rem;
      padding-bottom: 10rem;
    }
  }

  h1 {
    font-family: 'rye';
    margin-top: 2rem;
    font-size: 3rem;
    color: #881b1b;
    text-align: center;
    font-weight: normal;
    display: block;
    @include bp($point_6) {
      text-align: left;
    }
  }

  form {
    overflow: hidden;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 3.5rem;
    label {
      min-width: 200px;
      display: block;
      margin-bottom: 5px;
      margin-top: 2rem;
      color: #000;
    }
    input {
      width: 100%;
      max-width: 250px;
      padding: 10px;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      border: 1px solid #666;
      @include bp($point_6) {
        font-size: 2.5rem;
      }
    }
    textarea {
      font-size: 1.5rem;
      height: 130px;
      width: 100%;
      padding: 10px;
      margin-bottom: 1rem;
      @include bp($point_6) {
        font-size: 2.5rem;
      }
    }
    button {
      display: block;
      cursor: pointer;
      margin-top: 2.5rem;
      background-color: #c08805;
      color: #fff;
      font-size: 1.4rem;
      padding: 8px 12px;
      float: left;
      margin-bottom: 3rem;
      @include bp($point_4) {
        margin-top: 2.5rem;
        font-size: 1.7rem;
      }
      @include bp($point_6) {
        font-size: 2.5rem;
        margin-top: 3rem;
      }
      @include bp($point_7) {
        font-size: 2.5rem;
        margin-top: 2.5rem;
      }
    }
  }
  .response-wrapper {
    max-width: 500px;
    margin: 0 auto;
    display: block;
    float: left;
    margin-top: 3.5rem;
    margin-left: 2rem;
    font-size: 1.5rem;
    i {
      margin-left: 0.9rem;
    }
  }
  .response-wrapper p {
    color: #999;
    margin-top: -0.3rem;
  }
}
